.payment-method {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.payment-button {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 5px;
  justify-content: center;
  padding: 5px 0;
  border: 2px solid #45a049;
  color: #45a049;
  background: var(--background-color);
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
}

.payment-button:hover {
  background: #e8f5e9;
}

.payment-button.active {
  background: #45a049;
  color: white;
}

.payment-button-icon {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  transition: 0.5s;
}

.payment-button.active .payment-button-icon {
  color: white;
}
